import React from "react";
import { graphql } from "gatsby";
import { PageTemplateWrapper, Solutions } from "@resourcehub/resourcehub-components";
import { resourceServices } from "@resourcehub/resourcehub-services";
import configGenerator from "@resourcehub/resourcehub-configuration";
import "../../styles/main.less";

const Page = ({ data }) => {
    const { copy, items, page, site } = data;
    const featuredItemIds = ["recKH4erDob2cWMnU", "rec4Va9abOvfG5AFb", "recJe5WnAvXaEh3wr", "rec7EKOIAY17IPogQ"];
    const filteredItems = items.edges
        .filter((item) => featuredItemIds.includes(item.node.recordId))
        .sort(resourceServices.resourcesTitleSort);
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "enterprise",
        locale: site.siteMetadata.lang,
        page: "cn-enterprise-solutions"
    });
    return (
        <PageTemplateWrapper config={config}>
            <Solutions
                copyData={copy.data}
                data={data}
                domain={site.siteMetadata.legacy.domain}
                items={filteredItems}
                pageData={page.data}
                vertical="business"
                hideVerticals={true}
            />
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    query {
        copy: airtable(data: { title: { eq: "cn-copy" } }) {
            ...PagesCopyFragment
        }
        items: allAirtable(filter: { table: { eq: "Items" } }) {
            edges {
                node {
                    ...ItemsFragment
                }
            }
        }
        page: airtable(data: { title: { eq: "cn-corp-solutions" } }) {
            ...PagesSolutionsFragment
        }
        site {
            ...SiteFragment
        }

        ## IMAGES
        sol_section_1_img: file(relativePath: { eq: "images/sol-section-1-img.png" }) {
            childImageSharp {
                fixed(width: 649, height: 364) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 649, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sol_section_2_img: file(relativePath: { eq: "images/sol-section-2-img.png" }) {
            childImageSharp {
                fixed(width: 879, height: 678) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 879, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sol_section_4_img: file(relativePath: { eq: "images/sol-section-4-img.png" }) {
            childImageSharp {
                fixed(width: 935, height: 804) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 935, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tri_screen_lockup: file(relativePath: { eq: "images/tri-screen-lockup.png" }) {
            childImageSharp {
                fixed(width: 603, height: 379) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 603, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        k12_circle: file(relativePath: { eq: "images/k12-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hed_circle: file(relativePath: { eq: "images/hed-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        business_circle: file(relativePath: { eq: "images/business-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        pub_circle: file(relativePath: { eq: "images/pub-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
